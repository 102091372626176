import './App.css';
import videoCall from './assets/video_call.png';
import designer from './assets/designer.png';
import contact from './assets/contact.png';
import car from './assets/car.png';
import mobile from './assets/mobile.png';
import envelope from './assets/envelope.png';
import phone from './assets/phone.png';
import me from './assets/me.jpg';
import blue from './assets/blue.jpg';
import white from './assets/white.jpg';
import red from './assets/red.jpg';
import black from './assets/black.jpg';
import green from './assets/green.jpg';
import orange from './assets/orange.jpg';
import typing from './assets/typing.mp4';
import { Component } from 'react';

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showMenu: false,
      username: '',
      mail: '',
      message: '',
      sending: false
    }
  }

  render() {
    return (
      <div id="App">
        <nav className={this.state.showMenu ? "showMenu" : ""}>
          <ul onClick={() => this.setState({ showMenu: false })}>
            <li><a href="#home">Accueil</a></li>
            <li><a href="#me">Qui suis-je ?</a></li>
            <li><a href="#website">Création de sites web</a></li>
            <li><a href="#apps">Applications mobiles</a></li>
            <li><a href="#pricing">Tarifs</a></li>
            <li><a href="#contact">Contact</a></li>
          </ul>
        </nav>
        <section id="home">
          <div id="menuButton" onClick={() => this.setState(prevState => { return { showMenu: !prevState.showMenu } })}><h5>{this.state.showMenu ? "Fermer" : "Menu"}</h5></div>
          <video playsinline autoPlay muted loop id="myVideo">
            <source src={typing} type="video/mp4" />
          </video>
          <article id="brand">
            <h1>CHARLES</h1>
            <h2>INFORMATIQUE</h2>
            <h3>Freelance - Création de sites web et d'applications mobiles dans le Val d'Oise</h3>
          </article>
        </section>
        <section id="me">
          <article className="me">
            <article className="article_left">
              <h1>Qui suis-je ?</h1>
              <p>Passionné de technologies depuis mon enfance (et artiste dans l'âme), j'aime découvrir et partager mes connaissances dans ce domaine. Je mets ici à profit mes années d'expérience comme développeur web dans différentes structures afin de mieux répondre à vos besoins.</p>
              <p>Auto-entrepreneur, je propose mes 2 principaux services qui sont la <strong>création de sites web</strong> et <strong>la création d'applications mobiles iOS et Androïd</strong></p>
              <p><strong>Je me déplace dans le Val d'Oise</strong> et départements limitrophes.</p>
            </article>
            <article className="article_right">
              <img src={me} alt="Portrait de Charles" />
            </article>
          </article>
        </section>
        <section id="website">
          <article className="website">
            <article className="article_left">
              <h1>Création de sites web</h1>
                <ul>
                  <li>Rencontre avec vous en visio ou autour d'un café pour faire connaissance et établir vos besoins</li>
                  <li>Définition du cahier des charges</li>
                  <li>Envoi du devis</li>
                  <li>Démarrage du développement</li>
                  <li>Livraison</li>
                  <li>Maintenance et support</li>
                </ul>
            </article>
            <article className="article_right">
              <img src={designer} alt="Un développeur devant son ordinateur" />
            </article>
          </article>
        </section>
        <section id="apps">
          <article className="apps">
            <article className="article_left">
                <h1>Applications mobiles</h1>
                  <ul>
                    <li>Rencontre avec vous en visio ou autour d'un café pour faire connaissance et établir vos besoins</li>
                    <li>Définition du cahier des charges</li>
                    <li>Envoi du devis</li>
                    <li>Démarrage du développement</li>
                    <li>Livraison</li>
                    <li>Maintenance et support</li>
                  </ul>
              </article>
              <article className="article_right">
              <img src={mobile} alt="Un smartphone" />
            </article>
          </article>
        </section>
        <section id="pricing">
          <article className="pricing">
            <h1>Tarifs</h1>
            <div className="container">
              <div className="panel pricing-table"> 
                <div className="pricing-plan">
                <img src={designer} alt="Un développeur devant son ordinateur" className="pricing-img" />
                  <h2 className="pricing-header">Création d'un site web</h2>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">Achat du nom de domaine (si disponible)</li>
                    <li className="pricing-features-item">Développement du site</li>
                    <li className="pricing-features-item">Maintenance et support</li>
                  </ul>
                  <span className="pricing-price">Sur devis</span>
                </div>      
                <div className="pricing-plan">
                  <img src={mobile} alt="Un smartphone" className="pricing-img" />
                  <h2 className="pricing-header">Applications mobiles</h2>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">Développement de l'application</li>
                    <li className="pricing-features-item">Maintenance et support</li>
                  </ul>
                  <span className="pricing-price">Sur devis</span>
                </div>
                <div className="pricing-plan">
                  <img src={car} alt="Une voiture sur la route" className="pricing-img" />
                  <h2 className="pricing-header">Déplacement</h2>
                  <ul className="pricing-features">
                    <li className="pricing-features-item">Déplacements dans le Val d'Oise et départements limitrophes</li>
                  </ul>
                  <span className="pricing-price">15 &euro;</span>
                </div>
              </div>
            </div>
          </article>
        </section>
        <section id="contact">
          <article className="contact">
            <article className="article_left">
                <h1>Me contacter</h1>
                <div><span><img src={phone} alt="Un téléphone" /></span><h2> &#x30;&#x36; &#x30;&#x37; &#x32;&#x38; &#x34;&#x31; &#x32;&#x33;</h2></div>
                <div><span><img src={envelope} alt="Une envelope" /></span><h2>&#x63;&#x6f;&#x6e;&#x74;&#x61;&#x63;&#x74;&#x40;&#x63;&#x68;&#x61;&#x72;&#x6c;&#x65;&#x73;&#x69;&#x6e;&#x66;&#x6f;&#x72;&#x6d;&#x61;&#x74;&#x69;&#x71;&#x75;&#x65;&#x2e;&#x66;&#x72;</h2></div>
              </article>
              <article className="article_right">
                <img src={contact} alt="Des pictogrammes avec les différentes manières de contacter" />
              </article>
          </article>
        </section>
        <footer>charlesinformatique.fr &copy; { new Date().getFullYear() }</footer>
      </div>
    );
  }
}

export default App;
